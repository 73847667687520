.MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
    height: 300px !important;
    overflow: scroll
  }


/* input[name="phoneNumber"]::-webkit-outer-spin-button,
input[name="phoneNumber"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[name="phoneNumber"] {
  -moz-appearance: textfield;
} */