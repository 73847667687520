.Imagesize {
  width: 210px !important;
  height: 130px !important;
  object-fit: contain;
  margin: auto;
  overflow: hidden;
}

.uploadedimage {
  width: 80px;
  height: 80px;
}

.imageContainer {
  margin-right: 20px;
  margin-bottom: 30px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
