.react-datepicker__input-container input {
  min-height: 38px;
  border-radius: 0.5rem !important;
  font-size: 0.875rem !important;
  color: hsl(0, 0%, 20%) !important;
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding-left: 10px;
}

.react-datepicker__input-container input:focus {
  border-color: #2486ff !important;
  box-shadow: 0 0 0 1px #2486ff;
}

.react-datepicker__input-container input::placeholder {
  /* padding-left: 10px; */
  color: #8392ab !important;
  font-family: "Roboto", sans-serif;
}

.react-datepicker__close-icon {
  padding: 0 !important;
}
.react-datepicker__close-icon::after {
  height: 10px !important;
  width: 10px !important;
  padding: 0 !important;
  background-color: #344767 !important;
}

/* .react-datepicker__input-container input{
    width: 100px !important;
} */

.select__control {
  z-index: 0 !important;
  position: relative !important;
}

.select__menu {
  font-size: 14px;
}

#tableBox::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3); */
  background-color: white;
  border-radius: 10px;
}

#tableBox::-webkit-scrollbar {
  width: 7px;
  height: 2px;
  background-color: white;
}

#tableBox::-webkit-scrollbar-thumb {
  background-color: #c5c7c5;
  border-radius: 10px;
}

#tableId::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: white;
}

#tableId::-webkit-scrollbar {
  width: 2px;
  height: 7px;
  background-color: white;
}

#tableId::-webkit-scrollbar-thumb {
  background-color: #c5c7c5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

button.Mui-disabled {
  color: #cac8c7 !important;
}

.react-time-picker__inputGroup {
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;

  font-size: 0.875rem !important;
  padding-bottom: 4px !important;
  color: #8392ab !important;
  padding-top: 4px !important;

  height: 1.8rem !important;
  border: 1px solid #d2d6da !important;
  border-radius: 8px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.timePicker {
  height: 2.5rem;
  border-radius: 7px;
  border: 1px solid #cecece;
}

.react-tabs__tab-list {
  margin-bottom: 0px !important;
}

.react-tabs__tab {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15.416px;
  color: #3a416f;
  padding: 10px 18px !important;
}

.react-tabs__tab--selected {
  background: #3a416f !important;
  border-color: #3a416f !important;
  color: white !important;
  border-radius: 15.416px 15.416px 0px 0px !important;
}

/* .react-datepicker__input-container input{
    width: 100px !important;
} */

.date-picker-custom-v1 {
  width: 140px;
}

.date-picker-custom-v2 {
  width: 100px;
}
.date-picker-custom-v3 {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
